import { NgModule } from '@angular/core';
import { CommonModule, LowerCasePipe } from '@angular/common';
import { BankInformationService } from './bank-information/bank-information.service';
import { CloseModal } from './close-modal/close-modal';
import { HttpRedirect302 } from './http-302/http-302';
import { HubspotSimulation_Helper } from './hubspot/simulation/hubspot-simulation';
import { ProcessBlockingControlAndRedirectViaStatus } from './process-blocking-and-redirect-via-status/process-blocking-control-and-redirect-via-status';
import { CreateProcessUser } from './process-user/create/create-process-user';
import { RedirectProcessStatus } from './process-user/redirect/redirect-process-status';
import { stageFormValidateHelper } from './process-user/stage-form-validate-helper/stage-form-validate-helper.component';
import { RedirectStep } from './redirect-step/redirect-step';
import { CreateUser } from './user/create/create-user';
import { GetUserMe } from './user/me/get-user-me';
import { TermsAcceptanceService } from './user/terms-acceptance/terms-acceptance.service';
import { LoadResourcesPage } from './load-resources-page/load-resources-page.services';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    BankInformationService,
    CloseModal,
    HttpRedirect302,
    HubspotSimulation_Helper,
    ProcessBlockingControlAndRedirectViaStatus,
    CreateProcessUser,
    RedirectProcessStatus,
    stageFormValidateHelper,
    RedirectStep,
    CreateUser,
    GetUserMe,
    TermsAcceptanceService,
    LowerCasePipe,
    LoadResourcesPage
  ],
})
export class ClassHelperModule { }
