import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class ConvertQuestionsImportUtil {
  /* 
    Metodo para convertir el objeto guardado en localstorage y retornarlo en un array
    como lo pide Tripetto al importar respuestas en preguntas
  */
  convertQuestionImportsForm(inputObj:any) {
    const outputArr = [];
    const jsonQuestion = JSON.parse(inputObj);
      
    this.cleanNumericValues(jsonQuestion);

    for (const key in jsonQuestion) {
      if (jsonQuestion.hasOwnProperty(key)) {
        outputArr.push({ name: key, value: jsonQuestion[key] });
      }
    }
    return outputArr;
  }

  /* 
    Eliminamos puntos y comas para valores numericos, ya que tripetto no reconoce el numero
    con seprador de miles
  */
 
  cleanNumericValues(obj: any) {
    let obj_clean;
    for (const prop in obj) {
      if (typeof obj[prop] === 'string' && obj[prop].startsWith('$')) {
        // Si el valor comienza con "$", eliminamos puntos y comas
        obj[prop] = obj[prop].replace(/[.,]/g, '').substring(1); // Eliminamos puntos y comas y el primer caracter "$"
      }
      obj_clean = obj;
    }

    return obj_clean;
  }
}