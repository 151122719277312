import { isPlatformBrowser } from '@angular/common';
import { ElementRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import confetti from 'canvas-confetti';

@Injectable({
  providedIn: 'root'
})
export class ConfettiService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ){

  }

  celebrate(element: string, model: string) {
    if (isPlatformBrowser(this.platformId)) {
      const pollElement = (attempts: number) => {
        const ELEMENT_WITH_CANVAS: HTMLElement | null = document.querySelector(element);

        if (ELEMENT_WITH_CANVAS) {
          this.startConfetti({ nativeElement: ELEMENT_WITH_CANVAS } as ElementRef, model);
        } else if (attempts > 0) {
          setTimeout(() => pollElement(attempts - 1), 50);
        } else {
          console.error('Element not found after maximum attempts');
        }
      };

      // Start polling with 100 attempts
      pollElement(100);
    }
  }

  startConfetti(element: ElementRef, model: string) {
    const canvas = this.createCanvas();
    element.nativeElement.appendChild(canvas);

    const confettiInstance = this.createConfettiInstance(canvas);

    switch (model) {
      case 'snow':
        this.startSnowConfetti(confettiInstance);
        break;
      case 'school_pride':
        this.startSchoolPrideConfetti(confettiInstance, ['#fff', '#fff'], 1000);
        break;
      case 'campaign_cupido':
        this.campaignCupido(confettiInstance, ['#e4526e', '#ffd5dd', '#e4526e',], 1000, 3, 1);
        break
      case 'school_pride_premium':
        /* this.startSchoolPrideConfetti(confettiInstance, ['#ffc000', '#0000'], 1700); */
        this.startSnowConfetti(confettiInstance, ['#ffc000', '#0000'], 3000, 4, 25);
        break;
      case 'cdt_completado':
        this.startSchoolPrideConfetti(confettiInstance, ['#5ac62d', '#111bff'], 1500);
        break;
      case 'fireworks':
        this.startFireworksConfetti(confettiInstance);
        break;
    }
  }

  private createCanvas(): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    Object.assign(canvas.style, {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0px',
      left: '0px',
      pointerEvents: 'none'
    });
    return canvas;
  }

  private createConfettiInstance(canvas: HTMLCanvasElement) {
    return confetti.create(canvas, {
      resize: true,
      useWorker: true
    });
  }

  private campaignCupido(confettiInstance: any, colors = ['#fff', '#fff'], duration = 2000, particle = 2, start_velocity = -1) {

    const animationEnd = Date.now() + duration;
    let skew = 1;

    var scalar = 3;
    var unicorn = confetti.shapeFromText({ text: '💘😍', scalar });

    const confettiSnow = () => {
      const timeLeft = animationEnd - Date.now();
      const ticks = Math.max(200, 500 * (timeLeft / duration));
      skew = Math.max(0.8, skew - 0.001);

      confettiInstance({
        spread: 360,
        ticks: ticks,
        decay: 0.94,
        startVelocity: start_velocity,
        colors: colors,
        particleCount: particle,
        shapes: ['star'],
        origin: { x: Math.random(), y: Math.random() * skew - 0.2 },
        gravity: this.randomInRange(0.4, 0.6),
        drift: this.randomInRange(-0.4, 0.4),
        scalar: 1.2,
      });

      

      if (timeLeft > 0) {
        requestAnimationFrame(confettiSnow);
      }
    };

    setTimeout(() => {
      confettiSnow();
    }, 0);

    
  }

  private startSnowConfetti(confettiInstance: any, colors = ['#fff', '#fff'], duration = 2000, particle = 2, start_velocity = -1) {

    const animationEnd = Date.now() + duration;
    let skew = 1;

    const confettiSnow = () => {
      const timeLeft = animationEnd - Date.now();
      const ticks = Math.max(200, 500 * (timeLeft / duration));
      skew = Math.max(0.8, skew - 0.001);

      confettiInstance({
        particleCount: particle,
        startVelocity: start_velocity,
        ticks: ticks,
        colors: colors,
        shapes: ['circle'],
        origin: { x: Math.random(), y: Math.random() * skew - 0.2 },
        gravity: this.randomInRange(0.4, 0.6),
        scalar: this.randomInRange(0.4, 1),
        drift: this.randomInRange(-0.4, 0.4)
      });

      if (timeLeft > 0) {
        requestAnimationFrame(confettiSnow);
      }
    };

    confettiSnow();
  }

  private startSchoolPrideConfetti(confettiInstance: any, colors: any[], time: number) {
    const end = Date.now() + time;

    const confettiSchoolPride = () => {
      confettiInstance({
        particleCount: 8,
        angle: 60,
        spread: 55,
        origin: { x: 0, y: 1 },
        colors: colors,
        startVelocity: 80
      });

      confettiInstance({
        particleCount: 8,
        angle: 120,
        spread: 55,
        origin: { x: 1, y: 1 },
        colors: colors,
        startVelocity: 80
      });

      if (Date.now() < end) {
        requestAnimationFrame(confettiSchoolPride);
      }
    };

    confettiSchoolPride();
  }

  private startFireworksConfetti(confettiInstance: any) {
    const duration = 5000;
    const animationEnd = Date.now() + duration;
    const options = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
    const colors = ['#111BFF', '#6B71FF', '#C4C6FF', '#070B60', '#111BFF'];

    const confettiFireworks = () => {
      const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          clearInterval(interval);
          return;
        }

        const particleCount = 50 * (timeLeft / duration);

        confettiInstance({
          ...options,
          particleCount,
          colors: colors,
          origin: { x: this.randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
        });

        confettiInstance({
          ...options,
          particleCount,
          colors: colors,
          origin: { x: this.randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
        });
      }, 250);
    };

    confettiFireworks();
  }


  randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }
}
