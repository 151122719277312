import { NgModule } from '@angular/core';

@NgModule({
    providers:[HttpRedirect302]
})

export class HttpRedirect302 {

    constructor() { }

    ngOnInit() {
    }

    RedirectPageWordpress_Https_302(page_url:any){
        const url = 'https://blog.mejorcdt.com';
        window.location.replace(url + page_url);
    }
}