import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class getDataSaveSimulation {
    private dataSubject = new BehaviorSubject<any>(null);
    public data$ = this.dataSubject.asObservable();

    setData(data: any) {
        this.dataSubject.next(data);
    }

    getData() {
        return this.dataSubject.value;
    }
}
