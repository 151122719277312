import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { provideHttpClient, withFetch} from '@angular/common/http'

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ClassHelperModule } from '@class/class-helper.module';
import {provideClientHydration} from '@angular/platform-browser';
import { HammerGestureConfig } from '@angular/platform-browser';

@NgModule({
  declarations:[
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ClassHelperModule,
    LazyLoadImageModule,
  ],
  bootstrap: [AppComponent],
  providers : [
    provideHttpClient(withFetch()), 
    provideClientHydration(),
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    },
    { provide: LOCALE_ID, useValue: 'es' }
  ]
  
})
export class AppModule { }
