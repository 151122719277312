import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSeparator'
})
export class ThousandSeparatorPipe implements PipeTransform {

  transform(nStr: any): any {
    if (nStr === '' || nStr === 0) {
      return '';
    }

    if (nStr === null) {
      return 0;
    }

    if (nStr === null || isNaN(nStr)) {
      return '0';
    }
    
    if(nStr != Number.isNaN(nStr)){
      let x, x1, x2, rgx;
      nStr += '';
      x = nStr.split('.');
      x1 = x[0];
      x2 = x[1];
      rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + '.' + '$2');
      }
      return x1 + (x2 ? `.${x2}` : '');
    }
    
  }

}
