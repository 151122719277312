import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TermsAcceptanceService {
  private readonly STORAGE_KEY = 'mejorcdt_luable_terms_acceptance';
  private acceptedByProcess = new Map<string, boolean>();

  acceptedByProcess$ = new BehaviorSubject<Map<string, boolean>>(this.acceptedByProcess);

  constructor(
  ) { }

  acceptTerms(id_process: string) {
    this.acceptedByProcess.set(id_process, true);
    // this.saveToLocalStorage();
    this.acceptedByProcess$.next(this.acceptedByProcess);
  }

  // loadFromLocalStorage(): Map<string, boolean> {
  //   const storedData = localStorage.getItem(this.STORAGE_KEY);
  //   return storedData ? new Map(JSON.parse(storedData)) : new Map<string, boolean>();
  // }

  // private saveToLocalStorage() {
  //   localStorage.setItem(this.STORAGE_KEY, JSON.stringify(Array.from(this.acceptedByProcess.entries())));
  // }

}
