import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {
  public receive_Notificacion_Subject = new ReplaySubject<any | null>(1); // Se utiliza ReplaySubject en lugar de BehaviorSubject
  receiveNotificacion = this.receive_Notificacion_Subject.asObservable().pipe(distinctUntilChanged());

  constructor() { }

  sendNotificacion(data: any) {
    this.receive_Notificacion_Subject.next(data);
  }

}
