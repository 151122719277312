<section class="position-relative">

  <button
  class="btn-close-modal"
  *ngIf="!modalData.textButtons"
  (click)="activeModal.close()">
    <i class="bi bi-x-lg"></i>
  </button>

  <div 
  *ngIf="modalData && modalData.title"
  class="modal-header justify-content-center position-relative modal-confirmation"
  [ngClass]="{
    'no-padding': modalData.no_padding_header
  }">
    <h4 
    class="modal-title" 
    [innerHTML]="modalData.title">
    </h4>
  </div>

  <div 
  class="modal-body text-center" 
  *ngIf="modalData && modalData.message"
  [ngClass]="{
    'no-padding': modalData.no_padding_header
  }">
    <p 
    class="mb-0" 
    [innerHTML]="modalData.message">
    </p>
  </div>

  <div 
  class="modal-footer justify-content-center"
  *ngIf="modalData.textButtons">

    <button 
    type="button" 
    class="btn btn-primary"
    (click)="confirm()"
    *ngIf="modalData.textButtons.confirm"
    [id]="modalData.textButtons && modalData.textButtons.confirm.id ? modalData.textButtons.confirm.id : null">
      {{ modalData.textButtons ? modalData.textButtons.confirm.text : 'Confirmar' }}
    </button>

    <button 
    type="button" 
    class="btn btn-secondary"
    (click)="cancel()"
    *ngIf="modalData.textButtons.cancel"
    [id]="modalData.textButtons && modalData.textButtons.cancel.id ? modalData.textButtons.cancel.id : null">
      <i class="bi bi-arrow-left"></i>
      {{ modalData.textButtons ? modalData.textButtons.cancel.text : 'Cancelar' }}
    </button>
  </div>
</section>