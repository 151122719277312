import { isPlatformBrowser } from '@angular/common';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@enviroment/environment';
import { of, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SaveSimulationService {

  public endpoint:string = 'save-simulation';
  public domain:any;
  public api_key:any;

  constructor(
    private http:HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 
    this.domain = `${environment.API_BASE_URL}${environment.version}`;
    this.api_key = environment.API_KEY;
  }

  POST_SIMULATION_HUBSPOT(data: any){
    if(isPlatformBrowser(this.platformId)){
      return this.http.post(this.domain + this.endpoint, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-api-key': this.api_key,
        }) 
      })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
    }
    return of({})
  }

  handleError(error: { error: { message: string; }; status: any; message: any; }) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error

      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

     if(error.status == "undefined" || error.status == "0" || error.status == "401"  || error.status == 0  || error.status == 401 ) {
        window.onbeforeunload = function() {
          localStorage.clear();
          return '';
        }; 
      }  
    }
    /* location.href = "/cdt-simulador"; */
    return throwError(errorMessage);
  }
}
