import { Injectable,PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})

export class LocalStorageUtil {

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    // Método para guardar variable en localstorage
    save(clave: string, valor: string): void {
        if (isPlatformBrowser(this.platformId)){
            return localStorage.setItem(clave, valor);
        }
    }
  
    // Método para eliminar variable en localstorage
    delete(clave: string): void {
        if (isPlatformBrowser(this.platformId)){
            return localStorage.removeItem(clave);
        }
    }
  
    // Método para obtener el valor de una variable en localstorage
    get(clave: string): string | null {
        if (isPlatformBrowser(this.platformId)){
            return localStorage.getItem(clave);
        }
        return null
    }

     // Método para verificar si existe la cookie
    check(clave: string): boolean {
        if (isPlatformBrowser(this.platformId)){
            return localStorage.getItem(clave) !== null;
        }
        return false
    }

}