import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StageFormValidateService } from '@apiShared/process-status/stage-form-validate/stage-form-validate.service';


@Injectable({
  providedIn: 'root'
})

export class stageFormValidateHelper {

  constructor(
    private stage_form_validate_services: StageFormValidateService,
    private router: Router,
  ){  }

  public StageFormValidateServices_Helper(objParamaters:any){
    return this.stage_form_validate_services.STAGE_FROM_VALIDATE(objParamaters).then((data) => {
      let body = data.body;
      let pathFront = objParamaters.pathFront;

      if(data.status === 200){
        if(pathFront != body.path){
          this.router.navigate([body.path]);
        }
      }
    });
  }
}