import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '@enviroment/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ValidateTurboCdtService {

  private domain:any;
  private endpoint:string = 'processes/validate-turbo-cdt';
  private api_key:any;

  constructor(
    private http:HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.domain = `${environment.API_BASE_URL}`;
    this.api_key = environment.API_KEY;
  }

  VALIDATE_TURBO_CDT(data: any): Promise<any>{
    if (isPlatformBrowser(this.platformId)) {
      return this.http.post(this.domain + this.endpoint, data,
        {
          observe: 'response',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': `processes-${this.api_key}`
          })
        }).toPromise()
        .then(this.extractData)
        .catch(this.extractData);
    }
    return Promise.reject('Server Side Rendering');
  }

  private extractData(res: any) {
    let body = res;
    return body;
  }
}
