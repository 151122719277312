import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { of, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AllProcessUserService {

  private domain:any;
  private endpoint:string = 'processes/get-processes';
  private api_key:any;

  constructor(
    private http:HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 
    this.domain = `${environment.API_BASE_URL}${environment.version}`;
    this.api_key = environment.API_KEY;
  }

  ALL_PROCESS_USER(idToken: any){
    if(isPlatformBrowser(this.platformId)){
      return this.http.get(this.domain + this.endpoint, 
        {
          observe: 'response',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': `${this.api_key}`,
            'Authorization': `Bearer ${idToken}`
          }) 
        }
      )
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
    } 
    return of({})
  }

  handleError(error: { error: { message: string; }; status: any; message: any; }) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      
    }
    return throwError(errorMessage);
  }
}
