export const environment = {
  baseUrl:                'https://mejorcdt.com',
  production:              true,
  API_KEY:                'Z6mrBFD09ndIz6nWUt6C',
  API_BASE_URL:           'https://api-mcdt.mejorcdt.com/',
  version:                'v1/',
  API_NEW_PDF:            'https://api-cdt.mejorcdt.com/new-pdf',
  API_BANK_RATES:         'https://api-cdt.mejorcdt.com/bank-rates',
  API_ADELAIDA:           'https://3giw1p5i3e.execute-api.us-east-1.amazonaws.com/pro/',
  S3_STATIC_FILES:        'https://d196s6wuwr5feg.cloudfront.net/assets',
  GTA_TRACKING_CODE       :'GTM-MZFSNS2',
  IMAGE_DYNAMIC_FIGURES   :'https://mcdt-static-pro.s3.amazonaws.com/images/web/las-mejores-tasas-en-mejorcdt-actualizadas.png'
};
