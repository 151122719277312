<div *ngIf="getCampaign">
    <div class="screenBloqe align-items-center" style=" justify-items: center;"
    [ngClass]="
    {
        'd-none': screenBloqued == false,
        'd-grid' : screenBloqued == true
    }
    ">
        <img [defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" [lazyLoad]="getCampaign.base_gif_load" width="70px" alt="">
    </div>
    <div class="modal-body px-3 px-md-3" style="opacity: {{ opacityLoadOTP }} ">
        <div class="content-modal-theme">
            <div class="container-fluid px-0" id="">

                <div id="modalBody" >
                    <div (click)="activeModal.dismiss()" class="btn-close-login">
                        <button class="btn-home-hover position-absolute fw-bold text-white" *ngIf="!disable_modal_step_notificacion_components">
                            <i class="bi bi-x-lg fw-bold"></i>
                        </button>
                    </div>
                    <div id="info-noticia">
                        <div class="row text-center mx-0">
                            <div class="col-12 px-0">
                                <div class="w-100 px-0">
                                    <img [defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" [lazyLoad]="imgLogin" class="img-thumbnail border-0 loginHeigth object-cover" alt="">
                                    <h2 class="fw-bolder text-blue-100 fs-4 mt-3">
                                        {{ titleHeader }}
                                    </h2>
                                    <p class="mt-2 lh-1 mb-4" *ngIf="controlRegisterUser">
                                        Por favor, regístrate ingresando tu nombre.
                                    </p>
                                    <p class="mt-2 lh-1 mb-4" *ngIf="controlFormSiginUp">
                                        Para continuar abriendo tu cdt queremos confirmar que nadie se está haciendo pasar por ti. Te vamos a enviar un código de seguridad a tu celular, por <b>WhatsApp</b> <i class="bi bi-whatsapp color-whatsapp"></i> y por <b>SMS</b>
                                    </p>
                                    <p class="mt-2 lh-1 mb-4" *ngIf="controlFormSendCode">
                                        Que te enviamos a tu celular terminado en <b class="text-blue-100">****{{cookies.get('phone-full').slice(-3)}} </b>. Te lo enviamos por WhatsApp <i class="bi bi-whatsapp color-whatsapp"></i>  y Mensaje de Texto 🤓
                                    </p>

                                    <div *ngIf="isErrorCreate" class="mb-3">
                                        <h6 class="text-danger mb-0 fw-bold">Ha sucedido un error</h6>
                                        <p class="mb-0 text-danger">
                                            No hemos podido registrarte debido a un error. Por favor, intentalo más tarde.
                                        </p>
                                    </div>


                                    <form autocomplete="on" method="POST" [formGroup]="Form_OTP" class="mt-2" (keyup.enter)="loginForm()">
                                        <div
                                            id="alert-msg"
                                            class="alert-message text-start col-12 mt-3"
                                            *ngIf="messageAlert.message && resp_conn_between_component.data.hide_alert != true"
                                            [ngClass]="{
                                                'alert-error'   : messageAlert.error === 401 || messageAlert.error === 404,
                                                'alert-info'    : messageAlert.error === 500
                                            }">
                                            <span [innerHTML]="messageAlert.message"></span>
                                            <span *ngIf="messageAlert.error == 404">
                                                <a (click)="returnSignUp()" class="fw-bold cursor-pointer text-danger">desde aqui</a>
                                            </span>
                                        </div>

                                        <div class="position-relative" *ngIf="controlFormSiginUp">
                                            <app-input-phone-international
                                                [submitted]="submitted"
                                                [forceIsRequired]="true"
                                                (inputPhoneInternational_Emit)="EmiteinputPhoneInternational($event)">
                                            </app-input-phone-international>
                                        </div>

                                        <div *ngIf="controlFormSendCode">

                                            <span
                                            class="link-hidd mt-0 mb-3 text-start"
                                            (click)="updatedSigUpElement()">
                                            ¿Te equivocaste al escribir tu número de celular? <b>Corrígelo aquí.</b>
                                            </span>

                                            <div class="d-flex mb-2">
                                                <div class="col-7 text-start resend-code">
                                                    <span
                                                    class="{{resendClassEnabled}} text-blue-100"
                                                    (click)="resendCode({
                                                        phone: cookies.get('phone-full'),
                                                        status: resendStatusCode,
                                                        call_code: false
                                                    })">Reenviar código</span>
                                                </div>
                                                <div class="col-5 text-end text-resend-code">
                                                    en: <b>{{timeLeft}}s</b>
                                                </div>

                                            </div>

                                            <app-input-otp
                                            [submitted]="submitted"
                                            (InputOTP_Emit)="EmiteinputOTP($event)">
                                            </app-input-otp>

                                            <div class="invalid-feedback mt-2 text-start"
                                            [ngClass]="
                                                {
                                                    'd-block': statusSigIN == 201,
                                                    'd-none' : statusSigIN == 200
                                                }
                                            ">
                                                <b>Lo sentimos</b>, ha excedido el limite de intentos. Por favor, vuelve a reenviar el código.
                                            </div>

                                            <div class="invalid-feedback mt-2 text-start"
                                            [ngClass]="
                                                {
                                                    'd-block': statusSigIN == 401,
                                                    'd-none' : statusSigIN == 200
                                                }
                                            ">
                                                <b>Código incorrecto</b>, tiene <b>1 intento</b> más.
                                            </div>

                                            <span
                                            class="link-hidd {{resendClassEnabled}}"
                                            (click)="resendCode({
                                                phone: cookies.get('phone-full'),
                                                status: resendStatusCode,
                                                call_code: true
                                            })">
                                                ¿No recibiste un código? <br> <b class="{{resendClassEnabled}} text-blue-100">Recíbelo en una llamada</b>
                                            </span>
                                        </div>

                                        <div *ngIf="controlRegisterUser">
                                            <input id="fullname" name="fullname" type="text" placeholder="Escribe tu nombre" class="form-control entry-field-icon border-field" formControlName="fullname"
                                            [ngClass]="
                                                {
                                                    'is-invalid': submitted && f['fullname'].errors,
                                                    'is-valid' : f['fullname'].errors === null
                                                }
                                            "/>

                                            <div class="invalid-feedback color-error"
                                            [ngClass]="
                                                {
                                                    'd-block': submitted && f['fullname'].errors,
                                                    'd-none' : f['fullname'].errors === null
                                                }
                                            ">
                                                El nombre debe tener almenos 3 carácteres
                                            </div>
                                        </div>

                                        <button *ngIf="!controlFormSendCode" id="botonEnviarDatos" type="button" class="btn mt-3 fw-bold fs-5 px-4 rounded-3 w-100 text-blue-100 border-blue-100 bg-hover-blue-150 text-hover-whit" (click)="loginForm()">
                                            {{ titleButton }}
                                        </button>
                                    </form>

                                    <p class="lh-1 mt-4 mb-2">
                                        ¿Algún problema? Escríbenos <a href="https://api.whatsapp.com/send?phone=573160234714&text=Hola,%20estoy%20tratando%20de%20continuar%20con%20mi%20proceso%20de%20CDT%20y%20no%20estoy%20recibiendo%20el%20C%C3%B3digo%20de%20verificaci%C3%B3n%20en%20mi%20celular" target="_blank" class="text-decoration-none fw-bold text-blue-100">aquí</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
