import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SharedServiceCompleted {
  private functionCompletedSource = new BehaviorSubject<any>(null);
  functionCompleted = this.functionCompletedSource.asObservable();

  constructor() {}

  setFunctionCompleted(data: any) {
    this.functionCompletedSource.next(data);
  }
}