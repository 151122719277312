import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { CookieUtil } from '@utils/cookies';
import { Observable, timer, startWith, map, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidateTokenService {

  constructor(
    private router: Router,
    public cookies : CookieUtil,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  private async isTokenExpired(): Promise<boolean> {
    if (isPlatformBrowser(this.platformId)){
      if (!this.cookies.get('id_token_user')) return true;

      const tokenParts = this.cookies.get('id_token_user').split('.');
      if (tokenParts.length !== 3) return true;

      const payload = JSON.parse(atob(tokenParts[1]));
      const expirationDate = payload.exp * 1000; // La expiración está en segundos, la convertimos a milisegundos

      return expirationDate <= Date.now();
    } 
    return false
  }

  getTokenExpirationStatus(): Observable<boolean> {
    if (isPlatformBrowser(this.platformId)){
      return timer(0, 30000).pipe( // Emite un valor cada 30 segundos
        startWith(0),
        switchMap(async () => {
          return await this.isTokenExpired();
        }),
      );
    }
    return of(false)
  }

}
