<div>
    <div class="modal-body modal-sm">
        <div class="content-modal-theme">
            <div class="container-fluid px-0" id="">
                <div id="modalBody">
                    <button class="btn-home-hover position-absolute btn-close-bank-not-allied btn-close-modal fw-bold text-white" aria-label="Close" (click)="activeModal.dismiss()">
                        <i class="bi bi-x-lg fw-bold"></i>
                    </button>
                    <div id="">
                        <div class="row text-center py-3 px-2">
                            <div class="col-12 px-2">
                                <div class="w-100 ">
                                    <img loading="lazy"[defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" [lazyLoad]="imageBankNotAllied" alt="" title="" class="img-thumbnail border-0">
                                </div>

                                <h2 class="color-mcdt-primary fw-bold mt-3">
                                    ¡Lo sentimos!
                                </h2>
                                <p class="mt-4 lh-tema text-justify">
                                    La conexión con <b class="color-mcdt-primary2 fs-5"> {{ selectedBank }}  </b> se encuentra temporalmente fuera de servicio. Contáctate con nuestro canal de atención <a href="https://api.whatsapp.com/send?phone=573160234714&text=Hola%20estoy%20en%20la%20p%C3%A1gina%20de%20MejorCDT.com%20y%20quisiera%20mas%20informaci%C3%B3n" class="color-mcdt-primary fw-bold">MejorCDT</a> para que encontremos una solución.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>