import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SimulateInvestmentService {

  private domain:any;
  private endpoint:string = 'simulate-investment';
  private api_key:any;

  constructor(
    private http:HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 
    this.domain = `${environment.API_BASE_URL}${environment.version}`;
    this.api_key = environment.API_KEY;
  }

  async SIMULATE_INVESTMENT(body: any): Promise<any>{
    if(isPlatformBrowser(this.platformId)){
      return this.http.post(this.domain + this.endpoint, body,
        {
          observe: 'response',
          headers: new HttpHeaders({
            
            'Content-Type': 'application/json',
            'x-api-key': this.api_key,
          }) 
        }
      ).toPromise()
      .then(this.extractData) 
      .catch(this.extractData);
    }
    return Promise.reject('Server Side Rendering');
  }

  private extractData(res: any) {
    let body = res;
    return body;
  }
}