import { Injectable } from '@angular/core';
import JsonBanks from '@utils/json/banks.json';
import { Globals } from '@config/global';

@Injectable({
  providedIn: 'root'
})
export class BankInformationService {

  private banks = JsonBanks;
  private idBanksNoElectronicSignature: any = [];

  constructor(
    public globals_var: Globals
  ) {
    this.idBanksNoElectronicSignature = [this.globals_var.ID_MUNDOMUJER, this.globals_var.ID_BANCAMIA];
  }

  checkBankReceivesElectronicSignature(idBank: number): boolean {
    if(this.idBanksNoElectronicSignature.includes(idBank)) {
      return false;
    }
    return true;
  }

  getIdBankFromName(bankName: string): number {
    let banks = this.banks.raw;
    for (const key in banks) {
      if (banks[key].bank == bankName) {
        return parseInt(key);
      }
    }
    return 0;
  }

}
