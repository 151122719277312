import { Injectable } from '@angular/core';
import { SharedServiceCompleted } from '@apiShared/shared-service/shared-services.service.compontent';
import { GetProcessStatusService } from 'src/app/api-shared/process-status/get/get-process-status.service';
import { RedirectStep } from '../../redirect-step/redirect-step';
import { CloseModal } from '../../close-modal/close-modal';
import JsonBanks from '../../../utils/json/banks.json'
import { CookieUtil } from '@utils/cookies';
import { NotificacionService } from '@utils/notificacion';

@Injectable({
  providedIn: 'root'
})

export class RedirectProcessStatus {
  /* Transformación para obtener el nombre del banco por medio del id */
  id_banks = JsonBanks;
  open_from_step_notificacion_components: any;
  constructor(
    private get_process_user: GetProcessStatusService,
    public cookies_util: CookieUtil,
    private _redirectStep: RedirectStep,
    private _closeModal: CloseModal,
    private _CompletedSharedServices:SharedServiceCompleted,
    private notificacion_components: NotificacionService,
  ) {}

  public RedirectProcessUser_Helper(objParamaters: any){
    return this.get_process_user.GET_PROCESS_STATUS(objParamaters.idProcess, objParamaters.idToken).then((data: any) => {
      let status = data.status;

      if(status === 401){
        this.notificacion_components.sendNotificacion({
          api : "send_information_error_401_unauthorized"
        })
      } else if(status === 200 || status === 201){

        let body = data.body;
        this.cookies_util.save('id_process', body.uuid, 1);

        /* La propiedad 'open_from_step' existe en el objeto data */
        /* Si existe, entonces quiere decir el componente login ha sido abierto desde un paso del tubo */
        if ('open_from_step' in objParamaters) {
          /* open_from_step comunicara a diferentes componentes su resultado, con el fin de activar funciones en otros componentes  */
          this.open_from_step_notificacion_components = objParamaters.open_from_step
        }

        /* Redireccionamos al formato de vinculación dependiendo del banco del nombre */
        this._redirectStep._RedirectStep({
          name_bank                     : this.id_banks['raw'][body.bank_id]['bank'],
          process_status                : body.process_status,
          id_bank                       : body.bank_id,
          required_identity_validation  : (body.extra.required_identity_validation && body.identity_validation === null),
          extra                         : body.extra,
          force_not_apply               : objParamaters.force_not_apply
        }).then(() => {
          /* Cerramos el modal segun el nombre de la clase que nos indique objParamaters */
        if ('modal' in objParamaters) {
          setTimeout(() => {
            this._closeModal._CloseModal(objParamaters.modal);
          }, 1000);
        }
        }).catch(error => {
          console.error("An error occurred during redirection:", error);
        });

        

        /* Comunicaremos al servicio la función que ha llamado RedirectProcessUser_Helper() ha sido exitosa, por tanto, el servicio podrá habilitar vistas y demás*/
        this._CompletedSharedServices.setFunctionCompleted({
          "function_completed": true,
          "open_from_step": this.open_from_step_notificacion_components
        });
      } else if(status === 404) {
        this.notificacion_components.sendNotificacion({
          api : "send_information_error_404_not_found_process"
        })
      } else {
        this.notificacion_components.sendNotificacion({
          api : "send_information_error_500_expiration_cookies"
        })
      }

    }), 200;
  }
}
