import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AllProcessUserService } from 'src/app/api-shared/process-status/all/all-process-user.service';
import { RegisterBonusService } from "@apiShared/user/register-bonus/register-bonus.service";
import { CreateProcessUser } from 'src/app/class/process-user/create/create-process-user';
import { RedirectProcessStatus} from 'src/app/class/process-user/redirect/redirect-process-status';
import { CloseModal } from 'src/app/class/close-modal/close-modal';
import { environment } from '@enviroment/environment';
import ProcessStatus from '@utils/json/process-status.json';
import Banks from '@utils/json/banks.json'
import { CookieUtil } from '@utils/cookies';
import { Globals } from '@config/global';
import { UtilsMethods_Services } from '@utils/utils';
import { getDataCampaign } from '@utils/get-campaign';
import { LocalStorageUtil } from '@utils/localstorage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertConfirmComponent } from '../../resources-alerts/alert-confirm/alert-confirm.component';
import { ConfettiService } from '@apiShared/confetti/confetti.service';

@Component({
  selector: 'app-all-process-view',
  templateUrl: './all-process-view.component.html',
  styleUrls: ['./all-process-view.component.css'],

})
export class AllProcessViewComponent implements OnInit {
  env = environment;
  AllProcessDataModal:any;
  closeResult: string = '';
  error_activate:boolean = false;
  error_text?:string;
  /*  Tendrá todo el json de los procesos del usuario */
  jsonAllProcessUser : any = [];
  /* Pasamos la información del json de process-status a variable */
  jsonProcessStatus = ProcessStatus;
  jsonBanks = Banks;
  /* Bloquearemos el modal mientras los servicios nos responden */
  screenBloqued = false;
  /* Tendra conexión con screeBloqued, ya que bajara la opacidad del body modal */
  opacityLoadGetProcess = 1;
  /*  */
  date_day = new Date();
  number_process:any;
  /*  */
  bank_id_simulation: any;
  bank_name_simulation: any;
  bank_amount_simulation: any;
  bank_term_simulation: any;
  bank_rate_simulation: any;
  bank_promo_bonus:boolean = false;
  phone_simulation: any;
  extra_simulation: any;
  /*  */
  screen_desktop?: boolean;
  screen_mobile?: boolean;
  /*  */
  show_view: any;
  step_notificacion_components: any;
  open_from_step_notificacion_components:any;
  id_token_user:any;
  show_tag_referred: boolean = false;

  getCampaign:any = {};
  private processes: any;
  constructor(
    public confetti: ConfettiService,
    public activeModal: NgbActiveModal,
    public breakpointObserver: BreakpointObserver,
    public cookies_util : CookieUtil,
    public globals_var: Globals,
    public utils: UtilsMethods_Services,
    public localstorage: LocalStorageUtil,
    public _getDataCampaign: getDataCampaign,
    private _closeModal: CloseModal,
    private get_all_process_user: AllProcessUserService,
    private register_code_referred: RegisterBonusService,
    private post_create_process_user: CreateProcessUser,
    private get_redirect_process_user: RedirectProcessStatus,
    private modal: NgbModal,
  ) {
    this._getDataCampaign.getCampaignDataUpdates().subscribe(data => {
      this.getCampaign = data;
    });
  }

  ngOnInit(): void {
    this.operatorView(this.AllProcessDataModal);
  }

  operatorView(data:any){
    this.id_token_user = data.id_token;
    /* Cerramos el modal */
    this._closeModal._CloseModal(data.modal);
    if(data.step == 2 || data.step == 7){
      /* Damos valor a la sección de crear proceso con el obj que nos llegue de la comunicación interna */
      this.bank_id_simulation = data.data.bank_id;
      this.bank_name_simulation = data.data.bank_name;
      this.bank_amount_simulation = data.data.bank_amount;
      this.bank_term_simulation = data.data.bank_term;
      this.bank_rate_simulation = data.data.bank_rate;
      this.phone_simulation = data.data.phone;
      this.extra_simulation = data.data.extra;

      if(this.extra_simulation.hasOwnProperty('activate_bonus_50')){
        this.bank_promo_bonus = this.extra_simulation.activate_bonus_50;
        delete this.extra_simulation.activate_bonus_50;
      }
    }
    /* La propiedad 'open_from_step' existe en el objeto data */
    /* Si existe, entonces quiere decir el componente login ha sido abierto desde un paso del tubo */
    if ('open_from_step' in data) {
      /* open_from_step comunicara a diferentes componentes su resultado, con el fin de activar funciones en otros componentes  */
      this.open_from_step_notificacion_components = data.open_from_step
    }
    /* Añadimos el paso actual en que se activo la vista */
    this.step_notificacion_components = data.step;
    this.allProcessUser(data.id_token);
    this.screenResolution();
    this.registerCodeReferred(data.id_token);
  }

  /* Consultamos la cookie de get-all-process-user dónde estará el json de todos los procesos del usuario */
  allProcessUser(id_token:any){
    this.LoadingAnimation(0.5, true)
    /* Consultamos el servicio para listar todos los procesos del usuario */
    return this.get_all_process_user.ALL_PROCESS_USER(id_token).subscribe((data: any) => {
      let response = data.body;
      if(this.number_process >= 1){
        /* Para evitar errores, subiremos nuevamente el id_token_user a una cookie */
        this.cookies_util.save('id_token_user', this.id_token_user, 1);
      }
      this.jsonAllProcessUser = this.utils.removeObjectsCompletedSatus(response.processes);
      this.number_process = this.jsonAllProcessUser.length;
      this.processes = response.processes;
      this.sendParamextraPromoBonus(response.processes);
      setTimeout(() => {
        this.LoadingAnimation(1, false)
      }, 1000);
    });
  }

  /*  */
  ClickCreateProcess(){

    this.LoadingAnimation(0.5, true);
    this.upElementError(false, '');
    let body = {
      data: {
        bank_id: this.bank_id_simulation,
        amount: parseInt(this.bank_amount_simulation),
        term: this.bank_term_simulation,
        rate: this.bank_rate_simulation,
        phone: this.phone_simulation,
        extra: this.extra_simulation
      },
      bank_name: this.bank_name_simulation,
      idToken: this.cookies_util.get('id_token_user'),
      modal: "btn-close-all-process"
    }
    const COOKIE_PROMO_BONUS = this.cookies_util.get('promo-bonus');

    if(this.extra_simulation && this.extra_simulation.hasOwnProperty("promo_bonus")){
      let amount_bono_regalo = 0;
      let bank_amount_simulation = parseInt(this.bank_amount_simulation);

      if(COOKIE_PROMO_BONUS == '300k'){
        amount_bono_regalo = 300000;
      } else if(COOKIE_PROMO_BONUS == '100k'){
        amount_bono_regalo = 100000;
      } else if(COOKIE_PROMO_BONUS == "50k"){
        amount_bono_regalo = 50000;
      }

      let amount_addition = bank_amount_simulation + amount_bono_regalo;
      body.data.amount = amount_addition;
    }

    /* Consultamos la funcion helper de crear proceso del usuario */
    this.post_create_process_user.CreateProcess_Helper(body).then( (userData =>  {

      if(userData === 400){
        this.LoadingAnimation(1, false);
        this.upElementError(true, '¡Oops! 😅 Hubo un problema temporal al intentar abrir tu CDT. No te preocupes, ¡dale otra oportunidad y vuelve a intentarlo! 💪😄');
      }

      if (userData === 200) {
        if (this.cookies_util.check('promo-bonus')) {
          this.cookies_util.delete('promo-bonus');
        }
        if (Number(this.cookies_util.get('refcode')) === 1) {
          this.cookies_util.delete('refcode');
        }
      }

    }) );
  }

  /*  */
  clickGetProcess(id_process:any){
    this.LoadingAnimation(0.5, true)
    /* subimos el id_process selecionado a las cookies */
    this.cookies_util.save('id_process', id_process, 1);
    /* Consumiremos el servicio processUser helper que nos redireccionará al paso segun el backend */
    this.get_redirect_process_user.RedirectProcessUser_Helper(
      {
        "idProcess": id_process,
        "idToken": this.cookies_util.get('id_token_user'),
        "open_from_step": this.open_from_step_notificacion_components,
        "modal": "btn-close-all-process"
      }
    );

  }

  LoadingAnimation(opacity: number, screenBlocked: boolean): void {
    this.opacityLoadGetProcess = opacity;
    this.screenBloqued = screenBlocked;
  }

  upElementError(activate:boolean, msg:string){
    this.error_activate = activate;
    this.error_text = msg;
  }

  /* Consultamos la cookie para consumir el nombre XD */
  searchCookie(cookie:any){
    let cookie_serch = this.cookies_util.get(cookie);
    /* Si el parametro es igual a plazo, transformamos de días a meses */
    if(cookie == 'plazo') {
      cookie_serch = String(Number(this.cookies_util.get(cookie)) / 30);
    }
    return cookie_serch;
  }

  /* Tipo de separador de miles que se utilizará en la vista */
  getThousendFormatView(amount: any){
    return this.utils.thousandsSeparator(amount);
  }

  /* Obtener fecha a letra */
  getDateLyric(date: any){
    const date_process = new Date(Date.parse(date));
    const opciones:any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };

    return date_process.toLocaleDateString('es-co', opciones).replace('de', 'de')
  }

  /* Dependiendo la resolucion - desktop o mobile, cargara diferentes elementos */
  screenResolution(){
    /* Dependiendo de la vista responsive, cargará cierta div padre de la vista */
    this.breakpointObserver.observe(["(min-width: 767px)"]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        //Entonces si el width es superior a 767, será la versión Desktop mostrando su propio diseño
        this.screen_desktop = true;
        this.screen_mobile = false;
      } else {
        //Entonces si el width es inferio a 767, será la versión Mobile mostrando su propio diseño
        this.screen_desktop = false;
        this.screen_mobile = true;
      }
    });
  }

  /* Monstramos mensaje de PROMO de temporada que típicamente corresponde a un bono */
  sendParamextraPromoBonus(processes: any) {
    const refValidPromo = [
      "Sebas0624",
      "Tian0625",
      "LP_NicolasAbril",
      "LP_FinanciaTuVida",
      "LP_ClaudiaUribe",
      "LP_ParejaYFinanzas",
      "LP_AlterinativaInversiones"
    ];

    const refAmountPromo = ["Sebas0624"];
    const anyTermPromo = ["Sebas0624"];

    const COOKIE_PROMO_BONUS = this.cookies_util.get('promo-bonus');
    const refPromo = this.cookies_util.get('ref');

    const amountCondition = refValidPromo.includes(refPromo) && refAmountPromo.includes(refPromo) ? 950000 : 1000000;
    let transform_bonus_amount = 0;
    let value_bonus_promo = '';
    let message_error = '';

    const influencers_firs_cdt = ['LP_NicolasAbril', 'LP_FinanciaTuVida', 'LP_ClaudiaUribe', 'LP_ParejaYFinanzas', 'LP_AlterinativaInversiones']

    const bonusMapping:any = {
      '300k': { value: 'bonus_300k', amount: 300000 },
      '100k': { value: 'bonus_100k', amount: 100000 },
      '50k': { value: 'bonus_50k', amount: 50000 }
    };

    if (
        COOKIE_PROMO_BONUS &&
        parseInt(this.bank_amount_simulation) >= amountCondition &&
        (this.bank_term_simulation * 30 >= 360 || anyTermPromo.includes(refPromo))
    ) {
        const selectedBonus = bonusMapping[COOKIE_PROMO_BONUS];

        if (selectedBonus) {
            value_bonus_promo = selectedBonus.value;
            transform_bonus_amount = selectedBonus.amount;
        }

        if (processes.length > 0) {
            const ref = this.cookies_util.get('ref');

            const isValidProcess = processes.every((process: { process_status: string; }) => {
                if (!influencers_firs_cdt.includes(ref)) {
                    if (process.process_status !== 'LEAD-SIN-FORMULARIO') {
                        message_error = 'Tienes procesos de apertura de CDT con nosotros que no han sido terminados y ya has avanzado más allá de la etapa de diligenciamiento del formulario.';
                        return false;
                    }
                } else if (process.process_status === 'DECEVAL-ENVIADO-AL-CLIENTE') {
                    message_error = 'Ya tienes un CDT con nosotros.';
                    return false;
                }
                return true;
            });

            if (isValidProcess && this.bank_promo_bonus) {
                this.extra_simulation['promo_bonus'] = value_bonus_promo;
                this.activateOpenAlertConfirmation(transform_bonus_amount);
            } else {
                this.deactiveOpenAlertConfirmation(message_error);
            }
        } else if (this.bank_promo_bonus) {
            this.extra_simulation['promo_bonus'] = value_bonus_promo;
            this.activateOpenAlertConfirmation(transform_bonus_amount);
        }
    }
  }


  registerCodeReferred(token: string): void {
    let refcode = this.cookies_util.get('refcode');
    let name    = this.cookies_util.get('fullname').split(' ')[0];
    if (refcode) {
      this.show_tag_referred = true;
      this.register_code_referred.REGISTER_BONUS({
        code: refcode,
        bonus_type: 'REFERRAL'
      },token).then((data: any) => {

        if (data.status !== 201) {
          let messageError: string;
          switch (data.error.errors.code) {
            case "C001":
              messageError = "ya tienes este enlace registrado en un proceso";
              break;
            case "C002":
              messageError = "ya tienes un enlace de referido registrado";
              break;
            case "C003":
              messageError = "el enlace que usaste no existe";
              break;
            case "C004":
              messageError = "ya tienes CDTs activos";
              break;
            default:
              messageError = data.error.errors.message;
              break;
          }
          this.openAlertConfirmation({
            title: `
              <div class="d-block text-end text-size-sm text-black-100">
                ERROR: ${data.error.errors.code}
              </div>
              <div class="d-block text-center">
                <i class="mi mi-md mi-percent-starts"></i>
                <div class="d-block">
                  ¡Lo sentimos! No podemos validar tu enlace de referido
                </div>
              </div>
            `,
            message: `
              Lamentamos informarte que no pudiste aplicar para el beneficio de referido porque
              ${messageError}.
              <span class="text-blue-150 font-bold">
                No obstante, puedes continuar con tu proceso de apertura sin ningún problema.
              </span>
              <div class="d-block text-center my-2">
                <a href="https://api.whatsapp.com/send?phone=573160234714&text=Hola%2C%20estoy%20a%20punto%20de%20abrir%20un%20CDT%2C%20pero%20el%20bono%20no%20se%20registra" target="_blank" class="text-black-50">
                  Hablar con un asesor
                </a
              </div>
            `,
            no_padding_header: false,
            textButtons: {
              confirm: {
                text: "Aceptar",
                id: 'select_accept_denial_referred_code'
              }
            }
          });
          this.show_tag_referred = false;
          this.cookies_util.delete('refcode');
        } else {

          this.openAlertConfirmation({
            title: `
              <div class="d-block text-center">
                <i class="mi mi-md mi-check-circle"></i>
                <div class="d-block">
                  ¡Felicitaciones, ${name}!
                </div>
              </div>
            `,
            message: `
              Has aplicado correctamente al beneficio de invitado. Verás un
              <span class="font-bold">
                0.10% adicional
              </span>
              en la tasa de tu CDT al seleccionar el botón "Continuar"
            `,
            no_padding_header: false,
            textButtons: {
              confirm: {
                text: "Continuar"
              }
            }
          });
        }

      });
    }
  }

  activateOpenAlertConfirmation(bono_amount: number){
    let name = this.cookies_util.get('fullname').split(' ')[0];
    this.openAlertConfirmation({
      title: `
        <div class="d-block text-center">
          <i class="mi mi-md mi-check-circle"></i>
          <div class="d-block mt-3">
            ¡Felicitaciones, ${name}!
          </div>
        </div>
      `,
      message: `
        Has aplicado correctamente al Bono de apertura. Verás
        <span class="font-bold">
          $${this.utils.thousandsSeparator(bono_amount)} COP adicionales
        </span>
        en el monto de tu CDT al seleccionar el botón "Continuar"
      `,
      no_padding_header: false,
      textButtons: {
        confirm: {
          text: "Continuar"
        }
      }
    });

    this.confetti.celebrate('.modal-confirmation-all-process', 'fireworks');
  }

  deactiveOpenAlertConfirmation(messageError: string){
    this.openAlertConfirmation({
      title: `
        <div class="d-block text-center">
          <i class="mi mi-md mi-bell"></i>
          <div class="d-block mt-3">
            ¡Lo sentimos! No podemos aplicar el Bono a tu inversión
          </div>
        </div>
      `,
      message: `
        Lamentamos informarte que no pudiste aplicar para el Bono de apertura porque
        ${messageError}.
        <span class="text-blue-150 font-bold">
          No obstante, puedes continuar con tu proceso de apertura sin ningún problema.
        </span>
      `,
      no_padding_header: false,
      textButtons: {
        confirm: {
          text: "Aceptar",
        }
      }
    });
  }

  openAlertConfirmation(modalData: {title: string, message: string, textButtons: {}, no_padding_header: boolean}): void {
    const modalRef = this.modal.open(AlertConfirmComponent,
      {
        centered: true,
        windowClass: 'modal-confirmation-all-process'
      }
    );
    modalRef.componentInstance.modalData = modalData;
    modalRef.componentInstance.getResult().subscribe((result: boolean) => {
      if (result) {
        modalRef.close();
        this.ClickCreateProcess();
      }
    });
  }

}
