import {
  Injectable,
  Inject,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageUtil } from './localstorage';
import { off } from 'process';
import { NotificacionService } from './notificacion';

@Injectable({
  providedIn: 'root',
})
export class getDataCampaign {
  public campaignData: any;
  public campaignDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private renderer: Renderer2;
  campaignData$: Observable<any> = this.campaignDataSubject.asObservable();
  PATHS_BRANDSS_ALLOWED: any = [
    '/preguntas/coltefinanciera/flamingo',
    '/preguntas-ban100',
    '/preguntas-bancamia',
    '/preguntas-bancow',
    '/preguntas-bancomundomujer',
    '/preguntas-mibanco',
    '/preguntas-coltefinanciera',
    '/preguntas-financieradannregional',
    '/datos-personales',
    '/datos-financieros',
    '/sube-tus-documentos',
    '/asegura-tu-tasa',
    '/transfiere-el-dinero',
    '/cdt-completado',
    '/portal/login',
    '/portal/dashboard',
    '/login',
    '/verificando-firma',
    '/mis-procesos',
    '/continuar-proceso',
    '/no-podemos-continuar-con-tu-proceso',
    '/firma-los-documentos-en-fisico',
    '/validar-identidad',
    '/a-unos-minutos-de-abrir-tu-cdt',
    '/confirma-tus-datos',
    '/bienvenida-usuario-retorno',
    '/v2/preguntas/coltefinanciera/flamingo',
    '/v2/preguntas-ban100',
    '/v2/preguntas-bancamia',
    '/v2/preguntas-bancow',
    '/v2/preguntas-bancomundomujer',
    '/v2/preguntas-mibanco',
    '/v2/preguntas-credifamilia',
    '/v2/preguntas-coltefinanciera',
    '/v2/preguntas-financieradannregional',
    '/v2/preguntas-cdturbo',
    '/v2/datos-personales',
    '/v2/datos-financieros',
    '/cdt-claudiauribe'
  ];

  RETRY_ATTEMPTS_SECONDS = 100;
  RETRY_ATTEMPTS_MAX = 5;
  COUNT_INMEDIATE_FLAMINGO = 0;

  constructor(
    private router: Router,
    private rendererFactory: RendererFactory2,
    private localstorage: LocalStorageUtil,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private notification: NotificacionService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.getNotifications();
  }

  /**
   * getDataCampaign
   *
   * Esta clase gestiona las campañas publicitarias para la aplicación MejorCDT,
   * activando plantillas de marcas específicas basadas en la URL y parámetros de consulta.
   * También administra notificaciones de campañas específicas como Flamingo y permite
   * la actualización de las plantillas dinámicamente.
   *
   * Propiedades:
   * - campaignData: Almacena los datos actuales de la campaña.
   * - campaignDataSubject: BehaviorSubject que emite las actualizaciones de la campaña.
   * - campaignData$: Observable que permite suscribirse a las actualizaciones de la campaña.
   * - PATHS_BRANDSS_ALLOWED: Lista de rutas que permiten cargar campañas.
   * - RETRY_ATTEMPTS_SECONDS: Intervalo de tiempo para intentar cargar una campaña.
   * - RETRY_ATTEMPTS_MAX: Número máximo de intentos de carga.
   * - COUNT_INMEDIATE_FLAMINGO: Contador para el seguimiento de la activación de Flamingo.
   *
   * Métodos:
   * - getNotifications(): Suscribe a las notificaciones y activa la campaña Flamingo si es necesario.
   * - immediatelyActivateFlamingo(): Activa inmediatamente la campaña Flamingo.
   * - _getDictionaryCapaign(params): Carga los datos de la campaña desde un archivo JSON.
   * - campaignValueLowerCase(campaign): Convierte el nombre de una campaña a minúsculas.
   * - logCampaignQueryParam(): Regresa el valor del parámetro 'campaign' en la URL.
   * - activateCampaignData(): Intenta cargar los datos de la campaña basado en la URL.
   * - getCampaignData(): Devuelve los datos actuales de la campaña.
   * - getCampaignDataUpdates(): Devuelve un observable con las actualizaciones de la campaña.
   * - getCampaignTemplate(pathname): Aplica la plantilla correspondiente a la marca según la URL.
   */

  getNotifications(){
    this.notification.receive_Notificacion_Subject.subscribe((data) => {
      let response = data.data;
      let api_inmediately_campaign_force_flamingo = [
        "send_information_to_componentes_process",
        "send_activate_inmediately_campaign_flamingo"
      ]
      if (api_inmediately_campaign_force_flamingo.includes(data.api)) {
        this.COUNT_INMEDIATE_FLAMINGO++;
        if (response.bank_id == 27 && this.COUNT_INMEDIATE_FLAMINGO <= 1) {
          this.immediatelyActivateFlamingo();
        }
      }
    });
  }

  immediatelyActivateFlamingo() {
    const PATHNAME_PAGE = this.router.url.split('?')[0];
    this.RETRY_ATTEMPTS_MAX = 0;
    this._getDictionaryCapaign({
      url: '../../assets/i18n/flamingo.json',
      pathname: PATHNAME_PAGE,
      execute_template: true
    }).subscribe();
  }

  _getDictionaryCapaign(params: any): Observable<any> {
    return this.http.get<any>(params.url).pipe(
      tap((data) => {
        if(params.execute_template){
          this.getCampaignTemplate(params.pathname);
        }
        this.campaignDataSubject.next(data); // Emitir los nuevos datos
        this.campaignData = data; // Almacenar los datos en la caché
      })
    );
  }

  campaignValueLowerCase(campaign: string) {
    if (!campaign) {
      return 'mcdt';
    } else {
      return campaign.toLocaleLowerCase();
    }
  }

  logCampaignQueryParam() {
    const url = new URL(window.location.href);
    const campaign = url.searchParams.get('campaign');
    if (campaign) {
      return campaign;
    }

    return undefined;
  }

  activateCampaignData(): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      const FLAMINGO_VALUE = 'flamingo';
      let RETRY_ATTEMPTS = 0;
      let PATHNAME_PAGE = '';

      const POLL_FOR_CAMPAIGN = () => {
        PATHNAME_PAGE = this.router.url.split('?')[0];
        let CAMPAIGN_VALUE_PARAMS = this.logCampaignQueryParam();
        let BRAND_VALUE_LS = this.localstorage.get('brand_template');

        if (
          CAMPAIGN_VALUE_PARAMS != undefined &&
          this.PATHS_BRANDSS_ALLOWED.includes(PATHNAME_PAGE)
        ) {
          let URL_FETCH = '';

          if (CAMPAIGN_VALUE_PARAMS) {
            CAMPAIGN_VALUE_PARAMS = CAMPAIGN_VALUE_PARAMS.toLocaleLowerCase();

            if (
              CAMPAIGN_VALUE_PARAMS === FLAMINGO_VALUE ||
              FLAMINGO_VALUE == BRAND_VALUE_LS
            ) {
              URL_FETCH = '../../assets/i18n/flamingo.json';
            } else {
              URL_FETCH = '../../assets/i18n/mcdt.json';
            }

            clearInterval(POLLING_INTERVAL);
            return this._getDictionaryCapaign({
              url: URL_FETCH,
              pathname: PATHNAME_PAGE,
              execute_template: true
            }).subscribe();
          }
        } else {
          RETRY_ATTEMPTS++;
          if (RETRY_ATTEMPTS >= this.RETRY_ATTEMPTS_MAX) {
            clearInterval(POLLING_INTERVAL);
            return this._getDictionaryCapaign({
              url: '../../assets/i18n/mcdt.json',
              pathname: PATHNAME_PAGE,
              execute_template: true
            }).subscribe();
          }
        }

        return of({});
      };

      const POLLING_INTERVAL = setInterval(
        POLL_FOR_CAMPAIGN,
        this.RETRY_ATTEMPTS_SECONDS
      );
      
    }
    return of({});
  }

  getCampaignData(): any {
    return this.campaignData;
  }

  getCampaignDataUpdates(): Observable<any> {
    return this.campaignDataSubject.asObservable();
  }

  getCampaignTemplate(pathname: string) {
    if (isPlatformBrowser(this.platformId)) {
      const BRAND_TEMPLATE = 'brand_template';
      const BRAND_FLAMINGO = 'brand_flamingo';
      const BRAND_MEJORCDT = 'brand_mcdt';
      const BRAND_CLAUDIAURIBE = 'brand_claudiauribe'; // Nuevo brand para ClaudiaUribe
      const FLAMINGO_VALUE = 'flamingo';
      const CLAUDIAURIBE_VALUE = 'claudiauribe'; // Nuevo valor para ClaudiaUribe
      const CAMPAIGN_PARAMS = 'campaign';
      const PATHNAME_PAGE = pathname;
      const ID_PROJECT_MCDT_ELEM = document.getElementById('project-mcdt');
      let CAMPAIGN_VALUE_PARAMS: any = this.logCampaignQueryParam();
      let BRAND_VALUE_LS = this.localstorage.get('brand_template');

      if (this.PATHS_BRANDSS_ALLOWED.includes(PATHNAME_PAGE)) {
        if (CAMPAIGN_VALUE_PARAMS || BRAND_VALUE_LS) {
          if (CAMPAIGN_VALUE_PARAMS == 'undefined') {
            if (BRAND_VALUE_LS) {
              CAMPAIGN_VALUE_PARAMS = BRAND_VALUE_LS;
              BRAND_VALUE_LS = CAMPAIGN_VALUE_PARAMS;
            }
          }

          if (CAMPAIGN_VALUE_PARAMS) {
            this.localstorage.save(
              BRAND_TEMPLATE,
              CAMPAIGN_VALUE_PARAMS.toLocaleLowerCase()
            );
          }

          // Lógica para Flamingo
          if (
            CAMPAIGN_VALUE_PARAMS == FLAMINGO_VALUE ||
            BRAND_VALUE_LS == FLAMINGO_VALUE
          ) {
            if (ID_PROJECT_MCDT_ELEM) {
              this.renderer.removeClass(ID_PROJECT_MCDT_ELEM, BRAND_MEJORCDT);
              this.renderer.addClass(ID_PROJECT_MCDT_ELEM, BRAND_FLAMINGO);
              const URL_PAGE = new URL(window.location.href);
              URL_PAGE.searchParams.set(CAMPAIGN_PARAMS, FLAMINGO_VALUE);
              window.history.replaceState({}, '', URL_PAGE.toString());
              this._getDictionaryCapaign({
                url: '../../assets/i18n/flamingo.json',
                pathname: PATHNAME_PAGE,
                execute_template: false
              }).subscribe();
            }
          }


          // Lógica para ClaudiaUribe
          else if (
            CAMPAIGN_VALUE_PARAMS == CLAUDIAURIBE_VALUE ||
            BRAND_VALUE_LS == CLAUDIAURIBE_VALUE ||
            PATHNAME_PAGE === '/cdt-claudiauribe'  // Activar si la ruta es /cdt-claudiauribe
          ) {
            if (ID_PROJECT_MCDT_ELEM) {
              this.renderer.removeClass(ID_PROJECT_MCDT_ELEM, BRAND_MEJORCDT);
              this.renderer.addClass(ID_PROJECT_MCDT_ELEM, BRAND_CLAUDIAURIBE);
              const URL_PAGE = new URL(window.location.href);
              URL_PAGE.searchParams.set(CAMPAIGN_PARAMS, CLAUDIAURIBE_VALUE);
              window.history.replaceState({}, '', URL_PAGE.toString());
              this._getDictionaryCapaign({
                url: '../../assets/i18n/claudiauribe.json',  // JSON de ClaudiaUribe
                pathname: PATHNAME_PAGE,
                execute_template: false
              }).subscribe();
            }
          } else {
            this.renderer.removeClass(ID_PROJECT_MCDT_ELEM, BRAND_FLAMINGO);
            this.renderer.removeClass(ID_PROJECT_MCDT_ELEM, BRAND_CLAUDIAURIBE); // Asegurarse de quitar ClaudiaUribe
            this.renderer.addClass(ID_PROJECT_MCDT_ELEM, BRAND_MEJORCDT);
          }
        } else {
          this.renderer.addClass(ID_PROJECT_MCDT_ELEM, BRAND_MEJORCDT);
        }
      } else {
        this.localstorage.delete(BRAND_TEMPLATE);
        this.renderer.removeClass(ID_PROJECT_MCDT_ELEM, BRAND_FLAMINGO);
        this.renderer.removeClass(ID_PROJECT_MCDT_ELEM, BRAND_CLAUDIAURIBE); // Asegurarse de quitar ClaudiaUribe
        this.renderer.addClass(ID_PROJECT_MCDT_ELEM, BRAND_MEJORCDT);
      }
    }
  }
  
}
