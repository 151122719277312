import { CreateService } from '@apiShared/user/create.service';
import { Injectable } from "@angular/core";
import { CookieUtil } from '@utils/cookies';

@Injectable({
  providedIn: 'root'
})
export class CreateUser {
  constructor(
    private post_create_user: CreateService,
    public cookies_util : CookieUtil,
  ){}


  CreateUser_Helper(objParamaters:any): Promise<any>{
    return new Promise((resolve) => {
      this.post_create_user.CREATE_USER(objParamaters.data, objParamaters.idToken).then(data => {
        if(data.satus === 200 || data.status === 201){
          this.cookies_util.save('id_token_user', objParamaters.idToken, 1);
          setTimeout(() => {
            resolve(200);
          }, 1000);
        } else {
          resolve(400);
        }
      });
    });
  }
}
