import { Injectable } from '@angular/core';
import { CookieUtil } from './cookies';
import { Globals } from '@config/global';
import { Router } from '@angular/router';
import { LocalStorageUtil } from './localstorage';
import { SessionStorageUtil } from './sessionStorage';

@Injectable({
  providedIn: 'root',
})

export class SignOut_Util {

  constructor(
    public cookie_util: CookieUtil,
    public local_storage: LocalStorageUtil,
    public session_storage: SessionStorageUtil,
    public globals_var: Globals,
    private router: Router
  ){}
  /*
    Metodo para cerrar sesión del usuario
  */
  SignOut(campaign:string){
    let redirect: any = {};
    let queryParams:any = {};
    this.cookie_util.delete('id_token_user');
    this.cookie_util.delete('id_process');
    this.cookie_util.delete('fullname');
    this.cookie_util.delete('phone-full');
    this.cookie_util.delete('phone-number');
    this.cookie_util.delete('data-cdts');
    this.cookie_util.delete('email');
    this.cookie_util.delete('timeCode');
    this.cookie_util.delete('lau');
    this.cookie_util.delete('flow_turbo_cdt');
    this.local_storage.delete('response_data_financial');
    this.local_storage.delete('response_data_personal');
    this.local_storage.delete('response_data_specific_ban100');
    this.local_storage.delete('response_data_specific_bancamia');
    this.local_storage.delete('response_data_specific_banco_w');
    this.local_storage.delete('response_data_specific_coltefinanciera');
    this.local_storage.delete('response_data_specific_dan_regional');
    this.local_storage.delete('response_data_specific_flamingo');
    this.local_storage.delete("form-definition-update-personal-information");
    this.local_storage.delete("form-definition-update-financial-information");
    this.local_storage.delete("form-definition-fill-vinculation");
    this.session_storage.delete('personal_data_updated');
    this.session_storage.delete('updating_financial_data');
    this.session_storage.delete('updating_personal_data');
    this.session_storage.delete('financial_data_updated');

    if(campaign != ''){
      if(campaign === this.globals_var.CAMPAIGN_MEJORCDT){
        redirect.router = '/cdt-simulador';
      } else {
        redirect.router = `/a-unos-minutos-de-abrir-tu-cdt`
        queryParams.campaign = this.globals_var.CAMPAIGN_FLAMINGO.toLowerCase();
      }
      setTimeout(() => {
        this.router.navigate([redirect.router], { queryParams })
      }, 1000);
    }
  }
}
