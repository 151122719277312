import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  isAndroid = false;
  isIOS     = false;
  isDesktop = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      const userAgent = navigator.userAgent;
      // Detectar Android
      this.isAndroid  = /android/i.test(userAgent);
      // Detectar iOS
      this.isIOS      = /iPad|iPhone|iPod/.test(userAgent);
      // Detectar Desktop (ni Android ni iOS)
      this.isDesktop  = !this.isAndroid && !this.isIOS && /Windows|Macintosh|Linux/i.test(userAgent);
    }
  }
}
