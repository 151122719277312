import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoadResourcesPage {

  private loadSubject = new Subject<string>();

  constructor() {
    this.initializeLoadEvent();
  }

  // Método para inicializar el evento window.onload
  private initializeLoadEvent(): void {
    window.onload = () => {
      this.loadSubject.next('Todos los recursos de la página web se han cargado correctamente.');
    };
  }

  // Método para obtener el observable del loadSubject
  getLoadObservable(): Observable<string> {
    return this.loadSubject.asObservable();
  }
}
