import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class CreateService {
  
  private domain:any;
  private endpoint:string = 'users/create';
  private api_key:any;

  constructor(
    private http:HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.domain = `${environment.API_BASE_URL}${environment.version}`;
    this.api_key = environment.API_KEY;
   }

  CREATE_USER(data: any, idToken: any){
    if(isPlatformBrowser(this.platformId)){
      return this.http.post(this.domain + this.endpoint, data, 
        {
          observe: 'response',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': `${this.api_key}`,
            'Authorization': `Bearer ${idToken}`
          }) 
        }).toPromise()
        .then(this.extractData)
        .catch(this.extractData);
    }
    return Promise.reject('Server Side Rendering');
  }

  private extractData(res: any) {
    let body = res;
    return body;
  }

}
