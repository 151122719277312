import { Injectable } from '@angular/core';
import { GetBankUserVerificationService } from '@apiShared/get-bank-user-verification/get-bank-user-verification.service';

@Injectable({
    providedIn: 'root'
})
export class BankUserValidationHelper {

    constructor(
        private post_bank_user_verification: GetBankUserVerificationService
    ) { }

    bankUserVerification(ID_TOKEN_USER: string, ID_PROCESS: string): Promise<number> {
        return new Promise((resolve, reject) => {
            if (ID_TOKEN_USER && ID_PROCESS) {
                this.post_bank_user_verification.USER_VERIFICATION(ID_TOKEN_USER, { "process_uuid": ID_PROCESS })
                    .then(data => {
                        setTimeout(() => {
                            const VALIDATE_BANK_USER_VERIFICATION = (data.body.result === "04");
                            const VALIDATE_BANK_USER_VERIFICATION_UNDEFINED = (data.body.result === "07");

                            if (!VALIDATE_BANK_USER_VERIFICATION && !VALIDATE_BANK_USER_VERIFICATION_UNDEFINED) {
                                resolve(200);
                            } else {
                                resolve(400);
                            }
                        }, 3000);
                    })
                    .catch(error => {
                        reject(400);
                    });
            } else {
                reject(400);
            }
        });
    }
}
