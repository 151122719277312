import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BankInfoService {
  private domain:any;
  private api_key:any;
  private endpoint:string = 'banks/get-bank/';

  constructor(
    private http:HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 
    this.domain = `${environment.API_BASE_URL}`;
    this.api_key = environment.API_KEY;
  }

  BANKS_INFO(bank_id:number): Promise<any>{
    if(isPlatformBrowser(this.platformId)){
      return this.http.get(this.domain + this.endpoint + bank_id,
        {
          observe: 'response',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': `${this.api_key}`
          }) 
        }
      ).toPromise()
      .then(this.extractData) 
      .catch(this.extractData);
    }
    return Promise.reject('Server Side Rendering');
  }

  private extractData(res: any) {
    let body = res;
    return body;
  }
}
