import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StageFormValidateService {

  private domain:string;
  private endpoint:string = 'processes/stage-form-validate';
  private api_key:any;

  constructor(
    private http:HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 
    this.api_key = environment.API_KEY;
    this.domain = environment.API_BASE_URL;
  }

  STAGE_FROM_VALIDATE(objPamaters: any): Promise<any>{
    if(isPlatformBrowser(this.platformId)){
      return this.http.post(this.domain + this.endpoint, objPamaters.data,
        {
          observe: 'response',
          headers: new HttpHeaders({
            
            'Content-Type': 'application/json',
            'x-api-key': `processes-${this.api_key}`,
            'Authorization': `Bearer ${objPamaters.idToken}`
          }) 
        }
      ).toPromise()
      .then(this.extractData) 
      .catch(this.extractData);
    }
    return Promise.reject('Server Side Rendering');
  }

  private extractData(res: any) {
    let body = res;
    return body;
  }
}
