import { Injectable } from '@angular/core';
import { SaveSimulationService } from '@apiShared/hubspot/save-simulation.service';
import { Globals } from '@config/global';
import { NotificacionService } from '@utils/notificacion';
import { getDataSaveSimulation } from './get-data-save-simulation';


@Injectable({
  providedIn: 'root'
})

export class HubspotSimulation_Helper {

    constructor(
      public globals_var: Globals,
      private notification: NotificacionService,
      private apiSaveSimulation_Hubspot: SaveSimulationService,
      private set_data_save_simulation: getDataSaveSimulation
    ) {}

    HUBSPOT_SIMULATION(body:any){
        const data:any = {
          "amnt": body.amount,
          "phone": body.phone,
          "name": body.name,
          "srcurl": body.srcurl,
          "ref": body.ref,
          "urlpdf": body.urlpdf
        }

        if(body.bankname === this.globals_var.FLAMINGO){
          data.platform = this.globals_var.FLAMINGO;
          data.external_company = this.globals_var.FLAMINGO;
        }

        if(body.adv){
            data.adv = body.adv;
        }

        return this.apiSaveSimulation_Hubspot.POST_SIMULATION_HUBSPOT(data).subscribe((data: any) => {
          this.notification.sendNotificacion({
            api: "api-notification-data-save-simulation",
            data: data
          });
          this.set_data_save_simulation.setData(data);

        });

    }
}
